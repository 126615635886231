import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, fetchUser } from './features/auth/store/auth';
import { Layout } from './layouts/Layout/Layout';
import { Route, Routes } from 'react-router-dom';
import { CalendarPage } from './pages/Calendar/Calendar';
import { ClubPage } from './pages/Club/Club';
import { createCustomTheme } from './theme';
import { ThemeProvider } from '@mui/material';
import { SupportPage } from './pages/Support/Support';

import styles from './App.module.scss';
import { EventModal } from './features/calendar/components/EventModal/EventModal';
import { fetchDicts, fetchFields } from './store/common';
import { ResidentModal } from './features/club/components/ResidentModal/ResidentModal';
import { ResourcesModal } from './features/dicts/components/ResourcesModal/ResourcesModal';
import { ManagementModal } from './features/club/components/ManagementModal/ManagementModal';
import { fetchManagement } from './features/club/store/club';
import { Survey } from './pages/Survey/Survey';
import { SurveyItemPage } from './pages/SurveyItem/SurveyItem';
import { fetchQuickLinks } from './features/quick-links/store/quick-links';
import { AddResidentModal } from './features/club/components/AddResidentModal/AddResidentModal';
import { InviteExistingModal } from './features/club/components/InviteExistingModal/InviteExistingModal';
import { InviteNewModal } from './features/club/components/InviteModal/InviteModal';
import { Editor } from './features/community-page/components/Editor/Editor';
import { Start } from './features/start/components/Start/Start';
import { AddEventModal } from './features/calendar/components/AddEventModal/AddEventModal';
import { RelatedCommunitiesModal } from './features/related-communities/components/RelatedCommunitiesModal/RelatedCommunitiesModal';
import { CommunityModal } from './features/related-communities/components/CommunityModal/CommunityModal';
import { LinkInvite } from './features/club/components/LinkInvite/LinkInvite';

export const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const community = useSelector((state) => state.community.community);

  const theme = createCustomTheme({
    theme: 'light',
    direction: 'ltr',
    responsiveFontSizes: 'true',
  });

  useEffect(() => {
    try {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    } catch (err) { }
  }, []);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDicts());
    dispatch(fetchManagement());
    dispatch(fetchQuickLinks());
    dispatch(fetchFields());
    dispatch(fetchProfile());
  }, []);

  if ((user && !user.isManager && !user.isInterviewer) || community === -1) {
    // window.open(process.env.REACT_APP_UNAUTH_REDIRECT, '_top');
  }

  if (!user || !community) {
    return null;
  }

  return (
    <div className={styles.app}>
      <ThemeProvider theme={theme}>
        <Layout>
          <Routes>
            <Route path="/" element={<Start />} />
            <Route path="support" element={<SupportPage />} />
            {user.isManager && (
              <>
                <Route path="calendar" element={<CalendarPage />}>
                  <Route path="add" element={<AddEventModal />} />
                  <Route path=":eventId" element={<EventModal />} />
                </Route>
                <Route path="community-page" element={<Editor />} />
                <Route path="club" element={<ClubPage />}>
                  <Route path="dicts/:type" element={<ResourcesModal />} />
                  <Route path="add" element={<AddResidentModal />} />
                  <Route path="management" element={<ManagementModal />} />
                  <Route path="related" element={<RelatedCommunitiesModal />} />
                  <Route path="related/add" element={<CommunityModal />} />
                  <Route path="related/:communityId" element={<CommunityModal />} />
                  <Route path="link-invite" element={<LinkInvite />} />
                  <Route
                    path="invite/:cardId"
                    element={<InviteNewModal />}
                  ></Route>
                  <Route
                    path="invite-existing/:cardId"
                    element={<InviteExistingModal />}
                  ></Route>
                  <Route
                    path="invite-existing-from-card/:cardId"
                    element={<InviteExistingModal fromCard />}
                  ></Route>
                  <Route path=":cardId" element={<ResidentModal />} />
                </Route>
              </>
            )}
            {user.isInterviewer && (
              <>
                <Route path="survey" element={<Survey />}></Route>
                <Route
                  path="survey/:cardId"
                  element={<SurveyItemPage />}
                ></Route>
              </>
            )}
          </Routes>
        </Layout>
      </ThemeProvider>
    </div>
  );
};
