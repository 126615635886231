import { Grid } from '@mui/material';
import styles from './ClubFilters.module.scss';
import { AppSelect } from '../../../../components/ui/AppSelect/AppSelect';
import { useEffect, useState } from 'react';
import { api } from '@lib/api';

export const ClubFilters = ({ isArchive, onChange }) => {
  const [cities, setCities] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [city, setCity] = useState(null);
  const [community, setCommunity] = useState(null);
  const [role, setRole] = useState(null);
  const [status, setStatus] = useState(null);
  const [rolesCounts, setRolesCounts] = useState({
    resident: 0,
    manager: 0
  });
  const [statusesCounts, setStatusesCounts] = useState({
    unauthorized: 0,
    invited: 0,
    declined: 0,
    registered: 0,
  });

  const fetchFilters = async () => {
    const lastFilter = window.lastFilter;
    const params = {
      onlyMainCommunity: !!community?.value,
    };
    if (isArchive) {
      params.isArchive = 'true';
    }
    if (community) {
      params.communityId = community.value;
    }
    if (city) {
      params.city = city.value;
    }
    if (role) {
      params.role = role.value;
    }
    if (status) {
      params.status = status.value;
    }
    let oldValue;
    if (lastFilter === 'city') {
      oldValue = cities;
    } else if (lastFilter === 'community') {
      oldValue = communities;
    } else if (lastFilter === 'role') {
      oldValue = rolesCounts;
    } else if (lastFilter === 'status') {
      oldValue = statusesCounts;
    }
    const response = await api.get('/club/cards/filters/', {
      params
    });
    setRolesCounts(role && lastFilter === 'role' && oldValue ? oldValue : response.data.roles);
    setStatusesCounts(status && lastFilter === 'status' && oldValue ? oldValue : response.data.statuses);
    setCities(city && lastFilter === 'city' && oldValue ? oldValue : response.data.cities);
    setCommunities(community && lastFilter === 'community' && oldValue ? oldValue : response.data.communities);
    response.data.cities.forEach(item => {
      if (item.title === city?.label?.split(' (')[0]) {
        setCity({
          ...city,
          label: `${item.title} (${item.count})`
        });
      }
    });
    response.data.communities.forEach(item => {
      if (item.title === community?.label?.split(' (')[0]) {
        setCommunity({
          ...community,
          label: `${item.title} (${item.count})`
        });
      }
    });
    Object.keys(response.data.roles).forEach(value => {
      if (role?.value === value) {
        setRole({
          ...role,
          label: `${{
            manager: 'Менеджер',
            resident: 'Участник'
          }[value]} (${response.data.roles[value]})`
        });
      }
    });
    Object.keys(response.data.statuses).forEach(value => {
      if (status?.value === value) {
        setStatus({
          ...status,
          label: `${{
            unauthorized: 'Не зарегистрирован',
            invited: 'Приглашение отправлено',
            registered: 'Зарегистрирован',
            declined: 'Приглашение отклонено',
          }[value]} (${response.data.statuses[value]})`
        });
      }
    });

    window.lastFilter = null;
  };

  const citiesOptions = cities.map(item => ({
    label: `${item.title || 'Не указан'} (${item.count})`,
    value: item.title,
  }));

  const communitiesOptions = communities.map(item => ({
    label: `${item.title} (${item.count})`,
    value: item.id,
  }));

  const roleOptions = [
    { label: `Участник (${rolesCounts.resident})`, value: 'resident' },
    { label: `Менеджер (${rolesCounts.manager})`, value: 'manager' }
  ];

  const statusOptions = [
    {
      label: `Не зарегистрирован (${statusesCounts.unauthorized})`,
      value: 'unauthorized'
    },
    {
      label: `Приглашение отправлено (${statusesCounts.invited})`,
      value: 'invited'
    },
    {
      label: `Приглашение отклонено (${statusesCounts.declined})`,
      value: 'declined'
    },
    {
      label: `Зарегистрирован (${statusesCounts.registered})`,
      value: 'registered'
    },
  ];

  const setLastFilter = val => {
    window.lastFilter = val || null;
  }

  useEffect(() => {
    setLastFilter('city');
  }, [city]);
  useEffect(() => {
    setLastFilter('community');
  }, [community]);
  useEffect(() => {
    setLastFilter('role');
  }, [role]);
  useEffect(() => {
    setLastFilter('status');
  }, [status]);

  useEffect(() => {
    onChange && onChange({
      city: city?.value || null,
      community: community?.value || null,
      role: role?.value || null,
      status: status?.value || null,
      onlyMainCommunity: !!community?.value,
    });
  }, [city?.value, community?.value, role?.value, status?.value]);

  useEffect(() => {
    fetchFilters();
  }, [isArchive, city?.value, community?.value, role?.value, status?.value]);



  return (
    <div className={styles.filters}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className={styles.label}>Город</div>
          <div className={styles.select}>
            <AppSelect
              value={city}
              placeholder="Все"
              options={citiesOptions}
              onChange={setCity}
              isClearable />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles.label}>Сообщество</div>
          <div className={styles.select}>
            <AppSelect
              value={community}
              placeholder="Все"
              options={communitiesOptions}
              onChange={setCommunity}
              isClearable />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles.label}>Роль</div>
          <div className={styles.select}>
            <AppSelect
              value={role}
              placeholder="Все"
              options={roleOptions}
              onChange={setRole}
              isClearable />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles.label}>Статус</div>
          <div className={styles.select}>
            <AppSelect
              value={status}
              placeholder="Все"
              options={statusOptions}
              onChange={setStatus}
              isClearable />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
