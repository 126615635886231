import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from './RelatedCommunitiesModal.module.scss';
import { SidebarTabs } from "../../../../components/ui/SidebarTabs/SidebarTabs";
import { CommunitiesListIcon } from "../../../../icons/CommunitiesListIcon";
import { CommunityMentionIcon } from "../../../../icons/CommunityMentionIcon";
import { useEffect, useState } from "react";
import { CommunitiesList } from "../ComunitiesList/CommunitiesList";
import { Mention } from "../Mention/Mention";
import { useDispatch } from "react-redux";
import { fetchRelatedCommunities } from "../../store/related-communities";
import { usePageTitle } from "../../../../hooks/usePageTitle";

export const RelatedCommunitiesModal = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('list');
  const dispatch = useDispatch();
  const title = usePageTitle();

  const closeModal = () => {
    navigate('/club/');
  };

  useEffect(() => {
    title('Связанные сообщества');
    dispatch(fetchRelatedCommunities());
  }, []);

  return (
    <Dialog open={true} onClose={closeModal} className="wide-dialog">
      <DialogTitle>
        Связанные сообщества
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ width: '700px', overflowX: 'hidden' }}
        className={`paddingless ` + styles.modal}
      >
        <div className={styles.tabs}>
          <SidebarTabs
            value={tab}
            onChange={setTab}
            borderless
            tabs={[
              {
                title: 'Список сообществ',
                name: 'list',
                icon: CommunitiesListIcon
              },
              // {
              //   title: 'Упоминание',
              //   name: 'mention',
              //   icon: CommunityMentionIcon
              // },
            ]} />
        </div>
        <div className={styles.content}>
          {tab === 'list' && (
            <CommunitiesList />
          )}
          {tab === 'mention' && (
            <Mention />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
