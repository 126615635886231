import { DEFAULT_PALETTE_COLORS } from '../../../../data/constants';
import { AppButton } from '../../AppButton/AppButton';
import { ColorItem } from '../ColorItem/ColorItem';
import styles from './ColorPalette.module.scss';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { createPortal } from 'react-dom';
import { ColorPicker } from '../../ColorPicker/ColorPicker';

export const ColorPalette = ({ colors = [], value, onChange, onAvailableChanged }) => {
  const [available, setAvailable] = useState(colors);
  const [showPicker, setShowPicker] = useState(false);
  const [pos, setPos] = useState([0, 0]);

  const addMore = ev => {
    setAvailable(prev => [...prev, '#777777']);
    onChange('#777777');
    ev.stopPropagation();
    setPos([ev.pageX - 50, ev.pageY]);
    ev.preventDefault();
    setShowPicker(true);
  };

  const colorChosen = () => {
    setShowPicker(false);
  };

  const setNewColor = val => {
    setAvailable(prev => {
      const items = [...prev];
      items.pop();
      let result = [...items, val];
      if (result.length > 11) {
        result.shift();
      }
      return result;
    });
    setTimeout(() => {
      onChange(val);
    }, 100);
  };

  useEffect(() => {
    onAvailableChanged && onAvailableChanged(available);
  }, [available]);

  return <div className={styles.palette}>
    <div className={styles.colors}>
      {available.slice(0, 11).map(color => (
        <ColorItem color={color} checked={value === color} onClick={() => onChange(color)} key={color} />
      ))}
    </div>
    <AppButton size="small" color="secondary" startIcon={<AddIcon />} onClick={addMore}>Добавить свой</AppButton>
    {showPicker && createPortal(<ColorPicker x={pos[0]} y={pos[1]} onClose={colorChosen} onChange={setNewColor} />, document.body)}
  </div>
};
