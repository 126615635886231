import { ColorItem } from '../../../../components/ui/Colors/ColorItem/ColorItem';
import { CommunityAvatar } from '../CommunityAvatar/ComunityAvatar';
import styles from '../FormPreview.module.scss';
import { useSelector } from 'react-redux';
import { getGradientBackground } from '../../lib/background';
import { CommunityMembers } from '../CommunityMembers/CommunityMembers';

export const Preview = () => {
  const { title, slogan, website, telegram, about, bgColor } = useSelector(state => state.communityPage.data);
  const { title: originalTitle, isPublic } = useSelector(state => state.community.community);

  return (
    <div className={styles.page} style={{ background: getGradientBackground(bgColor) }}>
      <div className={styles.container}>

        <div className={styles.section}>
          <div className={styles.avatarAndTitle}>
            <div className={styles.avatar}>
              <CommunityAvatar />
            </div>
            <div className={styles.title}>{title || originalTitle}</div>
          </div>
          <div className={styles.subsection}>
            <div className={styles.subtitle}>Миссия сообщества</div>
            <div className={styles.subcontent + ' ' + styles.slogan}>{slogan || 'Не указано'}</div>
          </div>
          <div className={styles.subsection}>
            <div className={styles.subtitle}>Веб-сайт</div>
            <div className={styles.subcontent}>{website || 'Не указано'}</div>
          </div>
          <div className={styles.subsection}>
            <div className={styles.subtitle}>Телеграм</div>
            <div className={styles.subcontent}>{telegram || 'Не указано'}</div>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>О нас</div>
          <div className={styles.subcontent}>{about || 'Не указано'}</div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Тип сообщества</div>
          <div className={styles.subcontent}>{isPublic ? 'Открытое сообщество' : 'Закрытое сообщество'}</div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Цвет страницы</div>
          <div className={styles.subcontent}>
            <ColorItem color={bgColor} checked />
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Команда сообщества</div>
          <div className={styles.subcontent}>
            <CommunityMembers editable={false} />
          </div>
        </div>

      </div>

    </div>
  )
};
