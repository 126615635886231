import { useEffect, useState } from 'react';
import { ResidentAvatar } from '../../../features/club/components/ResidentAvatar/ResidentAvatar';
import { AppSelect } from '../AppSelect/AppSelect';
import styles from './SelectCommunityMembers.module.scss';
import { api } from '@lib/api';

export const SelectCommunityMembers = ({ multiple, cardId, value, onChange, exclude, cardsIds }) => {
  const [defaultCards, setDefaultCards] = useState([]);

  const findCards = async (search = '', isExclude = true) => {
    const response = await api.get(`/dashboard/club/cards/`, {
      params: {
        page: 1,
        limit: 15,
        search,
        isArchive: false,
        onlyRegistered: true,
        onlyMainCommunity: true
      },
    });
    const items = response.data.data;
    return items.filter(item => {
      if (!exclude) return true;
      return typeof exclude === 'function' ? !exclude(item) : !exclude.includes(item.id)
    }).map(item => ({
      value: item,
      label: <div className={styles.item}>
        <div className={styles.avatar}>
          <ResidentAvatar avatar={item.avatar} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{item.firstName} {item.lastName}</div>
          <div className={styles.email}>{item.email || item.phone || item.telegram || `#${item.id}`}</div>
        </div>
      </div>
    }));
  };

  const init = async () => {
    const cards = await findCards();
    setDefaultCards(cards);

    if (cardId) {
      const items = await findCards(cardId, false);
      const item = items.find(item => item.value.id === cardId);
      if (!item) return;
      onChange(item);
    }
  };

  const updateCardsIds = async () => {
    if (!cardsIds || !cardsIds.length) return;
    const selected = [];
    for (const cardId of cardsIds) {
      const items = await findCards(cardId, false);
      const item = items.find(item => item.value.id === cardId);
      if (!item) continue;
      selected.push(item);
    }
    onChange(selected);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    updateCardsIds();
  }, [cardsIds]);

  return (
    <AppSelect
      value={value}
      defaultValue={value}
      isAsync
      loadOptions={findCards}
      defaultOptions={defaultCards}
      options={defaultCards}
      placeholder="Поиск по участникам..."
      noOptionsMessage='Ни одного участника не найдено'
      onChange={onChange}
      isMulti={multiple}
    />
  );
};
