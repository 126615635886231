import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '../../../store/common';
import { formatTelegram } from '../../../util/format';

export const clubSlice = createSlice({
  name: 'club',
  initialState: {
    cards: [],
    page: 1,
    limit: 30,
    total: 0,
    isArchive: false,
    residentStatuses: [],
    management: {
      toInvite: [],
      toVerify: [],
    },
  },
  reducers: {
    setCards(state, action) {
      state.cards = action.payload;
    },
    appendCards(state, action) {
      state.cards = [...state.cards, ...action.payload];
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setIsArchive(state, action) {
      state.isArchive = action.payload;
    },
    setManagement(state, action) {
      state.management = {
        toInvite: action.payload.toInvite || [],
        toVerify: action.payload.toVerify || [],
      };
    },
    setResidentStatuses(state, action) {
      state.residentStatuses = action.payload;
    },
    updateResidentStatus(state, action) {
      const [oldValue, newValue] = action.payload;
      const items = [...state.residentStatuses];
      const index = items.indexOf(oldValue);
      if (index < 0) {
        return;
      }
      items.splice(index, 1, newValue);
      state.residentStatuses = items;
    },
    removeResidentStatus(state, action) {
      state.residentStatuses = state.residentStatuses.filter(
        (status) => status !== action.payload
      );
    },
    createResidentStatus(state, action) {
      state.residentStatuses = [...state.residentStatuses, action.payload];
    },
  },
});

export const updateCardInList = (data) => async (dispatch, getState) => {
  const { club: { cards } } = getState();
  const { cardId, common } = data;
  const items = [...cards];
  const index = items.findIndex(item => item.id === cardId);
  if (index < 0) return;
  const item = { ...items[index] };
  item.firstName = common.about.firstName;
  item.lastName = common.about.lastName;
  item.actualRequest = common.about.actualRequest || 'Не указан';
  item.email = common.contacts.emails[0];
  item.phone = common.contacts.phones[0];
  item.telegram = formatTelegram(common.contacts.telegrams[0]);
  items.splice(index, 1, item);
  dispatch(clubSlice.actions.setCards(items));
};

export const fetchInitCards =
  (search, isArchive, filters = {}) => async (dispatch, getState) => {
    dispatch(startProgress());
    dispatch(clubSlice.actions.setPage(1));
    dispatch(clubSlice.actions.setIsArchive(isArchive));
    const state = getState();
    const params = {
      page: 1,
      limit: state.club.limit,
      search,
      isArchive,
    };
    if (filters.city) {
      params.city = filters.city;
    }
    if (filters.community) {
      params.community = filters.community;
    }
    if (filters.role) {
      params.role = filters.role;
    }
    if (filters.status) {
      params.status = filters.status;
    }
    if (filters.onlyMainCommunity) {
      params.onlyMainCommunity = filters.onlyMainCommunity;
    }
    const response = await api.get(`/dashboard/club/cards/`, {
      params,
    });
    dispatch(clubSlice.actions.setCards(response.data.data));
    dispatch(clubSlice.actions.setTotal(response.data.total));
  };

export const fetchMoreCards =
  (search, isArchive, filters) => async (dispatch, getState) => {
    dispatch(startProgress());
    dispatch(clubSlice.actions.setIsArchive(isArchive));
    const state = getState();
    const nextPage = state.club.page + 1;
    const params = {
      page: nextPage,
      limit: state.club.limit,
      search,
      isArchive,
    };
    if (filters.city) {
      params.city = filters.city;
    }
    if (filters.community) {
      params.community = filters.community;
    }
    if (filters.role) {
      params.role = filters.role;
    }
    if (filters.status) {
      params.status = filters.status;
    }
    const response = await api.get('/dashboard/club/cards/', {
      params,
    });
    dispatch(clubSlice.actions.setPage(nextPage));
    dispatch(clubSlice.actions.appendCards(response.data.data));
    dispatch(clubSlice.actions.setTotal(response.data.total));
  };

export const fetchManagement = () => async (dispatch) => {
  const response = await api.get(`/dashboard/club/management/`);
  dispatch(clubSlice.actions.setManagement(response.data));
};

export const fetchResidentStatuses = () => async (dispatch) => {
  const response = await api.get(`/dashboard/club/resident-status/`);
  dispatch(clubSlice.actions.setResidentStatuses(response.data));
};

export const putResidentStatuses = () => async (dispatch, getState) => {
  const { residentStatuses } = getState().club;
  await api.patch(`/dashboard/club/resident-status/`, {
    items: residentStatuses,
  });
};

export const updateResidentStatus =
  (oldValue, newValue) => async (dispatch) => {
    dispatch(clubSlice.actions.updateResidentStatus([oldValue, newValue]));
    dispatch(putResidentStatuses());
  };

export const removeResidentStatus = (status) => async (dispatch) => {
  dispatch(clubSlice.actions.removeResidentStatus(status));
  dispatch(putResidentStatuses());
};

export const createResidentStatus = (status) => async (dispatch) => {
  dispatch(clubSlice.actions.createResidentStatus(status));
  dispatch(putResidentStatuses());
};
