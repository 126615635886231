import { useEffect, useState } from 'react';
import styles from './ColorPicker.module.scss';
import { HexColorPicker } from "react-colorful";


export const ColorPicker = ({ x, y, onClose, onChange }) => {
  const handleDocumentClick = () => {
    onClose && onClose();
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    return () => {
      document.addEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div className={styles.picker} onClick={ev => ev.stopPropagation()} style={{ top: `${y}px`, left: `${x}px` }}>
      <HexColorPicker onChange={onChange} />
    </div>
  )
};
