import { FormControl } from '@mui/material';
import { CommunityAvatar } from '../CommunityAvatar/ComunityAvatar';
import styles from '../FormPreview.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { communityPageSlice, finishEdit, saveData, updateUnsavedData } from '../../store/community-page.slice';
import { AppInput } from '../../../../components/ui/AppInput/AppInput';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ColorPalette } from '../../../../components/ui/Colors/ColorPalette/ColorPalette';
import { getGradientBackground } from '../../lib/background';
import { CommunityMembers } from '../CommunityMembers/CommunityMembers';
import { FieldError } from '../FieldError/FieldError';
import { Formik } from 'formik';
import { validatePageForm } from '../../lib/validation';
import { SimpleOptions } from '../../../../components/ui/SimpleOptions/SimpleOptions';

export const Form = forwardRef((props, ref) => {
  const data = useSelector(state => state.communityPage.data);
  const unsavedData = useSelector(state => state.communityPage.unsavedData);
  const { title: originalTitle } = useSelector(state => state.community.community);
  const dispatch = useDispatch();
  const submitButtonRef = useRef();
  const hash = window.location.hash?.slice(1) || null;
  const { community } = useSelector(state => state.community);

  const submitForm = (data) => {
    dispatch(updateUnsavedData(data));
    setTimeout(() => {
      dispatch(saveData());
      dispatch(finishEdit());
    }, 0);
  };

  const validateForm = (data) => {
    const errors = validatePageForm(data);
    dispatch(communityPageSlice.actions.setErrors(errors));
    return errors;
  };

  const updateBgColor = value => {
    dispatch(updateUnsavedData({
      ...data,
      bgColor: value,
    }));
  };

  const updateAvailableColors = val => {
    let value = [...val];
    if (value.length > 11) {
      value.shift();
    }
    dispatch(updateUnsavedData({
      ...data,
      availableColors: value,
    }));
  };

  useImperativeHandle(ref, () => {
    return {
      submit() {
        submitButtonRef.current.click();
      },
    };
  }, []);

  return (
    <div
      className={styles.page}
      style={{ background: getGradientBackground(unsavedData.bgColor) }}
      data-edit={hash === 'edit' ? 'edit' : ''}>
      <div className={styles.container}>
        <Formik
          initialValues={{
            title: data.title || originalTitle,
            slogan: data.slogan,
            website: data.website,
            telegram: data.telegram,
            about: data.about,
            type: community.isPublic ? 'open' : 'closed',
          }}
          validate={validateForm}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.section}>
                <div className={styles.avatarAndTitle}>
                  <div className={styles.avatar}>
                    <CommunityAvatar />
                  </div>
                  <div className={styles.title}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.title}
                        name="title"
                        onChange={handleChange}
                        placeholder="Название сообщества"
                        onBlur={handleBlur}
                        error={!!(touched.title && errors.title)} />
                    </FormControl>
                    {touched.title && errors.title && (
                      <FieldError>{errors.title}</FieldError>
                    )}
                  </div>
                </div>

                <div className={styles.subsection}>
                  <div className={styles.subtitle}>Миссия сообщества</div>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.slogan}
                        name="slogan"
                        onChange={handleChange}
                        placeholder="Кратко расскажите о миссии вашего сообщества"
                        onBlur={handleBlur}
                        error={!!!!(touched.slogan && errors.slogan)} />
                    </FormControl>
                    {touched.slogan && errors.slogan && (
                      <FieldError>{errors.slogan}</FieldError>
                    )}
                  </div>
                </div>

                <div className={styles.subsection}>
                  <div className={styles.subtitle}>Веб-сайт</div>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.website}
                        name="website"
                        onChange={handleChange}
                        placeholder="Укажите ссылку на сайт сообщества"
                        onBlur={handleBlur}
                        error={!!(touched.website && errors.website)} />
                    </FormControl>
                    {touched.website && errors.website && (
                      <FieldError>{errors.website}</FieldError>
                    )}
                  </div>
                </div>

                <div className={styles.subsection}>
                  <div className={styles.subtitle}>Телеграм</div>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.telegram}
                        name="telegram"
                        onChange={handleChange}
                        placeholder="Укажите telegram сообщества"
                        onBlur={handleBlur}
                        error={!!(touched.telegram && errors.telegram)} />
                    </FormControl>
                    {touched.telegram && errors.telegram && (
                      <FieldError>{errors.telegram}</FieldError>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionHeader}>Тип сообщества</div>
                <div className={styles.content}>
                  <p>Вы можете выбрать тип вашего сообщества ниже.</p>
                  <p>
                    <SimpleOptions
                      options={[
                        {
                          title: 'Открытое сообщество',
                          name: 'open'
                        },
                        {
                          title: 'Закрытое сообщество',
                          name: 'closed'
                        }
                      ]}
                      value={values.type}
                      onChange={(val) => setFieldValue('type', val)} />
                  </p>
                  <p>
                    Выбор типа влияет на процесс вступления в ваше сообщество.<br />
                    В открытое сообщество может вступить любой желающий.<br />
                    Для вступления в закрытое сообщество каждый потенциальный участник проходит обязательную верификацию менеджером сообщества.
                  </p>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionHeader}>О нас</div>
                <div className={styles.subsection}>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.about}
                        onChange={handleChange}
                        name="about"
                        multiline
                        rows={2}
                        sx={{ height: '66px' }}
                        onBlur={handleBlur}
                        error={!!(touched.about && errors.about)} />
                    </FormControl>
                    {touched.about && errors.about && (
                      <FieldError>{errors.about}</FieldError>
                    )}
                  </div>
                </div>
              </div>

              <button type="submit" style={{ display: 'none' }} ref={submitButtonRef}></button>
            </form>
          )}
        </Formik>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>Цвет страницы</div>
          <div className={styles.subcontent}>
            <ColorPalette colors={unsavedData.availableColors} onAvailableChanged={updateAvailableColors} value={unsavedData.bgColor} onChange={updateBgColor} />
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Команда сообщества</div>
          <div className={styles.subcontent}>
            <CommunityMembers editable={true} />
          </div>
        </div>
      </div>
    </div>
  );
});
