import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '../../../store/common';

export const relatedCommunitiesSlice = createSlice({
  name: 'relatedCommunities',
  initialState: {
    total: 0,
    communities: [],
  },
  reducers: {
    setTotal(state, action) {
      state.total = action.payload;
    },
    setCommunities(state, action) {
      state.communities = action.payload;
    },
  },
});

export const fetchRelatedCommunities = () => async (dispatch, getState) => {
  dispatch(startProgress());
  const state = getState();
  const response = await api.get(`/communities/${state.community.community.id}/related`);
  dispatch(relatedCommunitiesSlice.actions.setTotal(response.data.total));
  dispatch(relatedCommunitiesSlice.actions.setCommunities(response.data.data));
};

export const removeRelatedCommunity = (communityId) => async (dispatch, getState) => {
  const { relatedCommunities: { communities } } = getState();
  dispatch(startProgress());
  await api.delete(`/communities/related/${communityId}`);
  dispatch(relatedCommunitiesSlice.actions.setCommunities(communities.filter(com => com.id !== communityId)));
};
