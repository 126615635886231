import { FormControl } from '@mui/material';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import styles from './Residency.module.scss';
import { Field } from '@ui/Field/Field';
import { TextInput } from '@ui/TextInput/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { ResidentContext } from '@features/club/context/ResidentContext';
import { ExcludeButton } from './ExcludeButton/ExcludeButton';
import { ExcludeConfirmation } from './ExcludeConfirmation/ExcludeConfirmation';
import { ExcludedSuccess } from './ExcludeSuccess/ExcludedSuccess';
import { useNavigate } from 'react-router-dom';
import { api } from '@lib/api';
import {
  createResidentStatus,
  fetchInitCards,
  fetchManagement,
  fetchResidentStatuses,
  removeResidentStatus,
  updateResidentStatus,
} from '../../../store/club';
import { InviteModal } from '../../ManagementModal/InviteModal/InviteModal';
import { SuccessModal } from '@ui/SuccessModal/SuccessModal';
import { EditableTagList } from '../../../../../components/ui/EditableTagList/EditableTagList';
import { AppButton } from '@ui/AppButton/AppButton';
import { Message } from '@mui/icons-material';
import { ValidationContext } from '../../../context/ValidationContext';
import { FieldError } from '../../../../../components/ui/FieldError/FieldError';
import { MAX_RESIDENT_STATUS_COUNT } from '../../../../../data/constants';
import { ActualRequest } from './ActualRequest/ActualRequest';
import { ConfirmModal } from '../../../../../components/ui/ConfirmModal/ConfirmModal';

export const Residency = ({ isAdd }) => {
  const { data: resident, update } = useContext(ResidentContext);
  const { errors, touched } = useContext(ValidationContext);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [archiveSuccess, setArchiveSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { residentStatuses } = useSelector((state) => state.club);
  const [showInvite, setShowInvite] = useState(false);
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);

  const isRestore = resident?.isArchive;

  const excludeResident = async () => {
    setShowConfirmation(false);
    await api.patch(`/dashboard/club/archive/`, {
      cardId: resident.cardId,
      isArchive: !isRestore,
    });
    setArchiveSuccess(true);
  };

  const successClosed = () => {
    setArchiveSuccess(false);
    dispatch(fetchInitCards('', isRestore));
    navigate('/club/');
  };

  const excludeButtonClicked = () => {
    if (isRestore) {
      excludeResident();
    } else {
      setShowConfirmation(true);
    }
  };

  const invite = async () => {
    const cardsIds = [resident.cardId];
    await api.post(`/dashboard/club/invite/`, {
      cardsIds,
    });
    dispatch(fetchManagement());
    setShowSuccessInvite(true);
    setShowInvite(false);
  };

  const getStatusLabel = () => {
    let primary = {
      verified: 'Зарегистрирован',
      registered: 'Зарегистрирован',
      invited: 'Приглашение отправлено',
      unauthorized: 'Не зарегистрирован',
      declined: 'Приглашение отклонено',
      admin: 'Зарегистрирован',
      manager: 'Зарегистрирован',
      resident: 'Зарегистрирован'
    }[resident.status];
    if (
      !resident.userId &&
      resident.isInviteSent &&
      resident.status !== 'declined'
    ) {
      primary = 'Приглашение отправлено';
    }
    if (resident.isArchive) {
      primary = 'В архиве';
    }
    return primary;
  };

  const optionChanged = (oldValue, newValue) => {
    dispatch(updateResidentStatus(oldValue, newValue));
  };

  const optionRemoved = (option) => {
    dispatch(removeResidentStatus(option));
  };

  const optionCreated = (option) => {
    dispatch(createResidentStatus(option));
  };

  const removeResident = async () => {
    await api.delete(`/dashboard/club/cards/${resident.cardId}`);
    dispatch(fetchInitCards('', true));
    navigate('/club/');
  };

  const inviteAgain = async () => {
    if (resident.isUserExists) {
      navigate(`/club/invite-existing-from-card/${resident.cardId}/?`);
    } else {
      setShowInvite(true);
    }
  };

  useEffect(() => {
    dispatch(fetchResidentStatuses());
  }, []);

  return (
    <>
      <div className={styles.residency}>
        <SectionHeader
          title="Резидентство"
          description="Управление статусами резидента на платформе, информация о запросах, ваши комментарии"
        />
        <div className={styles.form}>
          <Field label="Статус резидента">
            <EditableTagList
              selected={resident.statuses || []}
              onChange={(items) => update('statuses', items)}
              options={residentStatuses}
              onOptionChange={optionChanged}
              onOptionRemove={optionRemoved}
              onOptionCreate={optionCreated}
              max={MAX_RESIDENT_STATUS_COUNT}
            />
          </Field>
          <Field label="Статус пользователя">
            <div className={styles.status}>
              <FormControl fullWidth>
                <TextInput disabled value={getStatusLabel()} />
              </FormControl>
              {(resident.status === 'invited' || resident.isInviteSent) &&
                !resident.userId && (
                  <div className={styles.inviteAgain}>
                    <AppButton
                      startIcon={<Message />}
                      variant="contained"
                      color="secondary"
                      onClick={inviteAgain}
                    >
                      Отправить еще раз
                    </AppButton>
                  </div>
                )}
            </div>
          </Field>
          <Field label="Актуальный запрос">
            <ActualRequest />
          </Field>
          <Field label="Комментарий">
            <FormControl fullWidth>
              <TextInput
                value={resident.comments}
                onChange={(ev) => update('comments', ev.target.value)}
                error={touched.submit && errors.comments}
              />
              {touched.submit && errors.comments && (
                <FieldError>{errors.comments}</FieldError>
              )}
            </FormControl>
          </Field>
        </div>
        <br />
        <div className={styles.exclude}>
          <ExcludeButton
            onClick={excludeButtonClicked}
            isRestore={isRestore}
            onRemove={() => setShowRemoveConfirmation(true)}
          />
        </div>
      </div>
      {showInvite && (
        <InviteModal
          onCancel={() => setShowInvite(false)}
          onSend={invite}
          items={[
            {
              name: `${resident?.common?.about?.firstName} ${resident?.common?.about?.lastName}`,
              email: resident?.common?.contacts?.emails[0],
            },
          ]}
        />
      )}
      {showSuccessInvite && (
        <SuccessModal
          description="Приглашения отправлены."
          onClose={() => setShowSuccessInvite(false)}
        />
      )}
      {showConfirmation && (
        <ExcludeConfirmation
          onConfirm={excludeResident}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
      {archiveSuccess && <ExcludedSuccess onClose={successClosed} isRestore={isRestore} />}
      {showRemoveConfirmation && (
        <ConfirmModal
          title="Удаление карточки резидента"
          description={`Вы действительно хотите удалить резидента ${resident.common.about.firstName} ${resident.common.about.lastName}?`}
          onDecline={() => setShowRemoveConfirmation(false)}
          onAccept={removeResident}
        />
      )}
    </>
  );
};
