import { useDispatch, useSelector } from 'react-redux';
import styles from './CommunityAvatar.module.scss';
import { DEFAULT_AVATAR, DEFAULT_AVATAR_BACKGROUND } from '../../../../data/constants';
import { PhotoIcon } from '../../../../icons/PhotoIcon';
import { useRef } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { showError, updateCommunityImage } from '../../store/community-page.slice';
import { api } from '@lib/api';
import { startProgress } from '../../../../store/common';
import { ImageViewer } from '../../../../components/ui/ImageViewer/ImageViewer';

export const CommunityAvatar = () => {
  const { id: communityId } = useSelector(state => state.community.community);
  const { avatar } = useSelector(state => state.communityPage.data);
  const { isEditing } = useSelector(state => state.communityPage);
  const inputRef = useRef();
  const dispatch = useDispatch();

  const style = {};

  const av = avatar ? `${process.env.REACT_APP_API_URL}v2/communities/pages/${communityId}/avatars/medium?random=${Date.now()}` : null;

  if (isEditing) {
    if (avatar) {
      style.backgroundImage = `url(${av})`;
    } else {
      style.background = DEFAULT_AVATAR_BACKGROUND;
    }
  } else {
    style.backgroundImage = `url(${avatar ? av : DEFAULT_AVATAR})`;
  }

  const selectFile = () => {
    inputRef.current.click();
  };

  const removeImage = async () => {
    await api.delete(`/communities/pages/${communityId}/avatar`);
    dispatch(updateCommunityImage(null));
  };

  const uploadFile = async file => {
    dispatch(startProgress());
    const blobReader = new FileReader();
    blobReader.onload = async (e) => {
      const blob = new Blob([new Uint8Array(e.target.result)], { type: file.type });
      const formData = new FormData();
      formData.append('avatar', blob, 'avatar.jpg');
      const response = await api.post(`v2/communities/pages/${communityId}/avatars`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(updateCommunityImage(response.data));
    };
    blobReader.readAsArrayBuffer(file);

  };

  const fileSelected = ev => {
    const file = ev.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      dispatch(showError('Максимальный размер изображения — 10 MB'));
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function (e) {

      const image = new Image();

      image.src = e.target.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (width < 400 || height < 400) {
          dispatch(showError('Минимальный размер изображения — 400 на 400 пикселей'));
        } else {
          uploadFile(file);
        }
        return true;
      };
    };
  };

  return (
    <ImageViewer className={styles.avatar} style={style} url={isEditing ? null : av}>
      <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={fileSelected} accept=".png,.jpg,.jpeg,.webp" />
      {
        !avatar && isEditing && (
          <div className={styles.uploadNew} onClick={selectFile}>
            <PhotoIcon />
          </div>
        )
      }
      {
        avatar && isEditing && (
          <div className={styles.remove} onClick={removeImage}>
            <DeleteForeverIcon color="error" />
          </div>
        )
      }
    </ImageViewer>
  )
};
