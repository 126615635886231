import { Add } from '@mui/icons-material';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import styles from './CommunitiesList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RelatedCommunity } from '../RelatedCommunity/RelatedCommunity';
import { removeRelatedCommunity } from '../../store/related-communities';
import { useState } from 'react';
import { RemoveConfirmation } from '../../../../components/ui/RemoveConfirmation/RemoveConfirmation';

export const CommunitiesList = () => {
  const { communities } = useSelector(state => state.relatedCommunities);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [community, setCommunity] = useState(null);

  const openCommunity = (ev, community) => {
    ev.stopPropagation();
    window.open(`${process.env.REACT_APP_UNAUTH_REDIRECT}community/${community.id}`, '_blank');
  };

  const editCommunity = (ev, community) => {
    ev.stopPropagation();
    navigate(`/club/related/${community.id}`);
  };

  const removeCommunity = (ev, community) => {
    setCommunity(community);
    ev.stopPropagation();
  };

  const proceedRemove = () => {
    dispatch(removeRelatedCommunity(community.id));
    setCommunity(null);
  };

  return (
    <>
      <div className={styles.list}>
        <div className={styles.description}>
          Вам доступно создание дополнительных связанных сообществ. Данные об участниках и событиях этих сообществ будут доступны в вашей админ-панели.
        </div>
        <div className={styles.button}>
          <Link to="/club/related/add/">
            <AppButton startIcon={<Add />} color="secondary">
              Создать связанное сообщество
            </AppButton>
          </Link>
        </div>
        <div className={styles.list}>
          {!communities.length && (
            <div className={styles.placeholder}>У вас пока нет связанных сообществ</div>
          )}
          {communities.map(community => (
            <RelatedCommunity
              title={community.title}
              managers={community.managers}
              onOpen={(ev) => openCommunity(ev, community)}
              onEdit={(ev) => editCommunity(ev, community)}
              onRemove={(ev) => removeCommunity(ev, community)} />
          ))}
        </div>
      </div>
      {community && (
        <RemoveConfirmation
          title={`Удалить сообщество «${community.title}»?`}
          onCancel={() => {
            setCommunity(null);
          }}
          onConfirm={proceedRemove}>
          Вы точно хотите удалить сообщество «{community.title}»? Это действие нельзя будет отменить.
        </RemoveConfirmation>
      )}
    </>
  );
};
