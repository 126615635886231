export const ACTUAL_REQUESTS = [
  {
    label: 'Ищу инвестора для расширения моего стартапа.',
    value: 'Ищу инвестора для расширения моего стартапа.',
  },
  {
    label: 'Нужен опытный маркетолог для разработки стратегии продвижения.',
    value: 'Нужен опытный маркетолог для разработки стратегии продвижения.',
  },
  {
    label:
      'Ищу квалифицированных IT-специалистов для укрепления команды разработки.',
    value:
      'Ищу квалифицированных IT-специалистов для укрепления команды разработки.',
  },
  {
    label: 'Необходимы рекомендации по оптимизации налогов для моего бизнеса.',
    value: 'Необходимы рекомендации по оптимизации налогов для моего бизнеса.',
  },
  {
    label: 'Ищу поставщика сырья для моего производственного предприятия.',
    value: 'Ищу поставщика сырья для моего производственного предприятия.',
  },
  {
    label: 'Нужен опытный юрист для решения правовых вопросов.',
    value: 'Нужен опытный юрист для решения правовых вопросов.',
  },
  {
    label: 'Ищу дизайнера для создания логотипа и фирменного стиля.',
    value: 'Ищу дизайнера для создания логотипа и фирменного стиля.',
  },
  {
    label:
      'Необходимы консультации по управлению персоналом и мотивации сотрудников.',
    value:
      'Необходимы консультации по управлению персоналом и мотивации сотрудников.',
  },
  {
    label: 'Ищу партнера для совместной разработки нового продукта.',
    value: 'Ищу партнера для совместной разработки нового продукта.',
  },
  {
    label: 'Нужна помощь в создании веб-сайта для моего бизнеса.',
    value: 'Нужна помощь в создании веб-сайта для моего бизнеса.',
  },
  {
    label: 'Ищу поставщика оборудования для обновления производственной линии.',
    value: 'Ищу поставщика оборудования для обновления производственной линии.',
  },
  {
    label:
      'Необходимы рекомендации по улучшению логистики и снижению затрат на доставку.',
    value:
      'Необходимы рекомендации по улучшению логистики и снижению затрат на доставку.',
  },
  {
    label: 'Ищу контакты инвестиционных фондов для привлечения капитала.',
    value: 'Ищу контакты инвестиционных фондов для привлечения капитала.',
  },
  {
    label: 'Нужен опытный бухгалтер для ведения финансовой отчетности.',
    value: 'Нужен опытный бухгалтер для ведения финансовой отчетности.',
  },
  {
    label:
      'Ищу специалиста по управлению проектами для реализации новых инициатив.',
    value:
      'Ищу специалиста по управлению проектами для реализации новых инициатив.',
  },
  {
    label:
      'Необходимы рекомендации по цифровому маркетингу и рекламе в социальных сетях.',
    value:
      'Необходимы рекомендации по цифровому маркетингу и рекламе в социальных сетях.',
  },
  {
    label:
      'Ищу агентство для проведения корпоративных мероприятий и тренингов.',
    value:
      'Ищу агентство для проведения корпоративных мероприятий и тренингов.',
  },
  {
    label: 'Нужна помощь в поиске клиентов за рубежом для экспорта продукции.',
    value: 'Нужна помощь в поиске клиентов за рубежом для экспорта продукции.',
  },
  {
    label: 'Ищу инструктора для обучения сотрудников новым навыкам.',
    value: 'Ищу инструктора для обучения сотрудников новым навыкам.',
  },
  {
    label: 'Необходимы рекомендации по оптимизации производственных процессов.',
    value: 'Необходимы рекомендации по оптимизации производственных процессов.',
  },
  {
    label: 'Ищу партнера для совместного участия в государственных тендерах.',
    value: 'Ищу партнера для совместного участия в государственных тендерах.',
  },
  {
    label:
      'Нужна помощь в разработке стратегии развития компании на следующий год.',
    value:
      'Нужна помощь в разработке стратегии развития компании на следующий год.',
  },
  {
    label:
      'Ищу внешнего консультанта для анализа финансовой эффективности проектов.',
    value:
      'Ищу внешнего консультанта для анализа финансовой эффективности проектов.',
  },
  {
    label:
      'Необходимы рекомендации по снижению издержек в рамках текущих операций.',
    value:
      'Необходимы рекомендации по снижению издержек в рамках текущих операций.',
  },
  {
    label:
      'Ищу поставщика услуг по обеспечению безопасности и охраны объектов.',
    value:
      'Ищу поставщика услуг по обеспечению безопасности и охраны объектов.',
  },
];
