import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './AddEventModal.module.scss';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import { EventForm } from '../EventForm/EventForm';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEvents } from '../../store/calendar';
import { api } from '@lib/api';
import { startProgress } from '../../../../store/common';
import { usePageTitle } from '../../../../hooks/usePageTitle';

export const AddEventModal = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const pageTitle = usePageTitle();

  pageTitle('Добавление события');

  const modalClosed = () => {
    navigate('/calendar/');
  };

  const add = async (data) => {
    delete data.repeat;
    dispatch(startProgress());
    await api.post('/calendar/events', data);
    dispatch(fetchEvents());
    modalClosed();
  };

  return (
    <Dialog open={true} onClose={modalClosed} className="wide-dialog">
      <DialogTitle>
        Новое событие
        <IconButton
          aria-label="close"
          onClick={modalClosed}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '700px' }} className="paddingless">
        <div className={styles.content}>
          <EventForm onSubmit={add} ref={formRef} />
        </div>
        <div className={styles.footer}>
          <div className={styles.spacing}></div>
          <div className={styles.cancel}>
            <AppButton
              color="secondary"
              variant="contained"
              onClick={modalClosed}
            >
              Отмена
            </AppButton>
          </div>
          <div className={styles.save}>
            <AppButton
              color="primary"
              variant="contained"
              type="button"
              onClick={() => formRef.current?.submit()}
            >
              Создать событие
            </AppButton>
          </div>
        </div>
      </DialogContent >
    </Dialog >

  );
};
